import React, { useEffect, useState } from "react";
import Image from "../../../../../components/Image";
import ContentApiService from "../../../services/ContentApiService";
import OptionalLinkWrapper from "../../../../../components/OptionalLinkWrapper";
import GeneralUtility from "../../../../../libs/GeneralUtility";

const CropApiImage = ({
  image,
  layout,
  className = "",
  wrapperClassName = "",
  lazy = true,
  handleLazyLoad = true,
  lazyClass = "lazy",
  apiDomain = "",
  linkHref,
  priority = false,
  useDefaultLink = false,
  getImageUrl = ContentApiService.getImageUrl,
}) => {
  const imageClasses = [];

  const [cropVariant, setCropVariant] = useState("desktop");

  /**
   * Due to the fact that NextOptimizedImages does not allow different urls
   * for the different SrcSets,
   * we have to determine the correct url on the client side.
   */
  useEffect(() => {
    const viewportWidth = window.innerWidth;
    if (viewportWidth < 768) {
      setCropVariant("mobile");
    } else if (viewportWidth >= 768 && viewportWidth < 1024) {
      setCropVariant("tablet");
    }
  }, []);

  if (!image) return null;

  const { uid = 0, cropVariants = [], alternative = "", title = "" } = image;

  if (parseInt(uid) === 0) {
    return null;
  }

  const src = getImageUrl(uid, { cropVariant }, true, apiDomain);

  let width = image.originalWidth;
  let height = image.originalHeight;

  // if crop variant exists for current
  // view port type (desktop / tablet / mobile) use cropped dimensions
  if (
    cropVariants &&
    !GeneralUtility.isEmptyObject(cropVariants) &&
    cropVariant in cropVariants &&
    "width" in cropVariants[cropVariant] &&
    "height" in cropVariants[cropVariant]
  ) {
    width = cropVariants[cropVariant].width;
    height = cropVariants[cropVariant].height;
  }

  if (lazy) {
    imageClasses.push(lazyClass);
  }

  const nextOptimizedImageEnabled = width && height;

  return (
    <OptionalLinkWrapper href={linkHref} useDefaultLink={useDefaultLink}>
      <Image
        src={src}
        width={width}
        height={height}
        nextOptimizedImage={nextOptimizedImageEnabled && !image.disableFeOptimization}
        handleLazyLoad={handleLazyLoad}
        className={`${className} ${imageClasses.join(" ")}`}
        wrapperClassName={wrapperClassName}
        priority={priority}
        alt={alternative}
        title={title}
        layout={layout}
      />
    </OptionalLinkWrapper>
  );
};

export default CropApiImage;
