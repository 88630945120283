import { SwiperSlide } from "swiper/react";
import React from "react";
import SwiperCore, { Autoplay, Navigation, Pagination, Lazy } from "swiper";
import styles from "./ApiImageSlider.module.scss";
import ApiImage from "../ApiImage";
import SwiperSlider from "../SwiperSlider/SwiperSlider";

SwiperCore.use([Autoplay, Navigation, Pagination, Lazy]);

const ApiImageSlider = ({ id, images }) => {
  if (images.length === 0) {
    return null;
  }

  return (
    <div className={styles.galleryImages}>
      <SwiperSlider
        id={id}
        slidesPerView={1}
        autoplay={{ delay: 5000 }}
        loop={false}
        lazy
      >
        {Object.values(images).map((figure, index) => (
          <SwiperSlide key={index} className={styles.swiperSlide}>
            <ApiImage
              key={index}
              image={figure.image}
              className="ce-image swiper-lazy"
              wrapperClassName="ce-image__wrapper-full-width"
              lazy
              handleLazyLoad={false}
              height={600}
            />
            {figure.figcaption && (
              <div className={styles.imageDescription}>{figure.figcaption}</div>
            )}
          </SwiperSlide>
        ))}
      </SwiperSlider>
    </div>
  );
};

export default ApiImageSlider;
