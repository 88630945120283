import React from "react";
import CropApiImage from "../CropApiImage";
import styles from "./ImageGallery.module.scss";
import imageSliderStyles from "../ApiImageSlider/ApiImageSlider.module.scss";
import ApiImageSlider from "../ApiImageSlider/ApiImageSlider";
import PagePathUtility from "../../../../../libs/PagePathUtility";
import getConfig from "next/config";
import ArticleApiService from "../../../../articles/services/ArticleApiService";

const ImageGallery = ({ data, className, id, getImageUrl }) => {
  const layoutMapping = {
    0: "center-top",
    1: "right-top",
    2: "left-top",
    8: "center-bottom",
    9: "right-bottom",
    10: "left-bottom",
    18: "intext-left",
    17: "intext-right",
    25: "image-behind",
  };

  const {
    items = {},
    imagewidth: imageWidth = 0,
    imageheight: imageHeight = 0,
    imageorient = 0,
    renderImagesAsSlider = 0,
  } = data;

  if (!items.row || !Array.isArray(items.row)) {
    return null;
  }

  const autoWidth = parseInt(imageWidth) === 0;

  if (parseInt(renderImagesAsSlider)) {
    // extract images to flat array from gallery row - column format
    const images = items.row.reduce((images, row) => {
      if (!row.column || !Array.isArray(row.column)) {
        return images;
      }
      return [...images, ...row.column];
    }, []);
    return <ApiImageSlider className={className} images={images} id={id} />;
  }

  return (
    <div
      className={`${styles[layoutMapping[parseInt(imageorient)]]} ${className}`}
    >
      {items.row.map((rowItem, rowIndex) => {
        const columnItems = rowItem.column;
        return (
          <div
            className={`grid-x ${styles.row}`}
            key={`gallery-row-${id}-${rowIndex}`}
          >
            {columnItems.map((image, columnIndex) => {
              const autoWidthClass = autoWidth
                ? `cell small-${12 / columnItems.length}`
                : "cell";

              let specialImageSizes = {};
              if (!autoWidth) {
                const { width } = image.image.dimensions;
                specialImageSizes = { width: `${width}px` };
              }

              const linkHref =
                PagePathUtility.createLinkWithBasepath(image.image.link) || "";

              return (
                <div
                  className={`${autoWidthClass} ${styles.item}`}
                  style={specialImageSizes}
                  key={`gallery-row-column-${id}-${rowIndex}-${columnIndex}`}
                >
                  <CropApiImage
                    image={image.image}
                    className="ce-image swiper-lazy"
                    wrapperClassName="ce-image__wrapper-full-width"
                    lazy
                    handleLazyLoad
                    linkHref={linkHref}
                    croppingVariants={image.image.cropVariants}
                    useDefaultLink={true}
                    getImageUrl={getImageUrl}
                  />
                  {image.figcaption && (
                    <div className={imageSliderStyles.imageDescription}>
                      {image.figcaption}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default ImageGallery;
